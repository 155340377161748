import {UserModel} from "interfaces";

export const userInitialState: UserModel = {
  description: "",
  username: "",
  _id: "",
  email: "",
  name: "",
  lastname: "",
  phone: "",
  countryId: "",
  cityId: "",
  stateId: "",
  birthday: "",
  address: "",
  role: 2,
  code: null,
  referralCode: "",
  myReferrals: [],
  deleted: false,
  picture: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  __v: 0,
};
