import moment from "moment";
import {Formik} from "formik";
import {useEffect} from "react";
import {Container} from "./styled";
import {ChevronLeft} from "react-feather";
import {useTheme} from "styled-components";
import {signUp} from "@redux/slices/auth/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {PhoneNumberUtil} from "google-libphonenumber";
import {initialValues, signUpSchema} from "./form.schema";
import {useLocation, useNavigate} from "react-router-dom";
import {findUserByReferralCode} from "@redux/slices/users/thunks";
import {
  Row,
  Logo,
  Line,
  Chip,
  Input,
  Column,
  Button,
  Avatar,
  IconButton,
  Typography,
  PhoneInput,
} from "components";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

interface RegisterProps {}

export const Register: React.FC<RegisterProps> = () => {
  const push = useNavigate();
  const {search} = useLocation();
  const dispatch = useAppDispatch();
  const {primary, black} = useTheme();
  const {referralUser, request} = useAppSelector("users");
  const referralCode: string | undefined = search.split("=")[1];

  useEffect(() => {
    if (referralCode) {
      dispatch(findUserByReferralCode(referralCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  return (
    <Container>
      <Row marginBottom={10} gap={10} paddingLeft={15}>
        <IconButton
          onClick={() => push("/")}
          style={{borderRadius: 100}}
          icon={<ChevronLeft color={black} />}
        />
        <Column gap={5}>
          <Logo width={100} variant="dark" />
          <Typography fontSize={12} fontFamily="Poppins-Medium">
            Gana, sueña y emprende
          </Typography>
        </Column>
      </Row>
      <Formik
        validateOnChange={true}
        validationSchema={signUpSchema}
        initialValues={{...initialValues, referralCode}}
        onSubmit={(payload) => {
          dispatch(signUp(payload));
        }}
      >
        {({
          dirty,
          values,
          errors,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
        }) => {
          let disabled = !(dirty && isValid);
          if (!disabled) {
            disabled = !isPhoneValid(values["phone"]);
          }

          return (
            <Column width="100%" padding="0 15px">
              <Column gap={0} width="100%">
                <Input
                  autoFocus
                  name="name"
                  label="Nombre"
                  placeholder="Centro de"
                  value={values["name"]}
                  touched={touched["name"]}
                  onBlur={handleBlur("name")}
                  helperErrorText={errors["name"]}
                  onChange={handleChange("name")}
                />
                <Input
                  name="lastname"
                  label="Apellido"
                  placeholder="Apuestas"
                  value={values["lastname"]}
                  touched={touched["lastname"]}
                  onBlur={handleBlur("lastname")}
                  helperErrorText={errors["lastname"]}
                  onChange={handleChange("lastname")}
                />
                <PhoneInput
                  label="Teléfono móvil"
                  value={values["phone"]}
                  touched={touched["phone"]}
                  helperErrorText={errors["phone"]}
                  onChangePhoneNumber={(phone) => {
                    setFieldValue("phone", phone);
                  }}
                />
                <Input
                  name="password"
                  type="password"
                  label="Contraseña"
                  placeholder="*********"
                  value={values["password"]}
                  touched={touched["password"]}
                  onBlur={handleBlur("password")}
                  helperErrorText={errors["password"]}
                  onChange={handleChange("password")}
                />
                <Input
                  type="date"
                  name="birthday"
                  placeholder="DD/MM/AAAA"
                  value={values["birthday"]}
                  label="Fecha de nacimiento"
                  onBlur={handleBlur("birthday")}
                  onChange={handleChange("birthday")}
                  helperErrorText={errors["birthday"]}
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                />
                <Line />
                {request["referralUser"]["success"] ? (
                  <Column gap={5}>
                    <Row alignItems="flex-start">
                      <Avatar
                        size={40}
                        border={`2px solid ${primary}`}
                        src={referralUser["picture"]}
                      />
                      <Column gap={0}>
                        <Chip backgroundColor={primary}>
                          Te ha invitado al Centro de Apuesta
                        </Chip>
                        <Typography fontFamily="Poppins-Medium">
                          {referralUser["name"]} {referralUser["lastname"]}
                          <Typography fontSize={13} fontFamily="Poppins-Light">
                            , @{referralUser["username"]}
                          </Typography>
                        </Typography>

                        <Typography fontSize={12}>
                          Código de referido: {referralUser["referralCode"]}
                        </Typography>
                      </Column>
                    </Row>
                  </Column>
                ) : (
                  <Input
                    name="referralCode"
                    placeholder="HS2024"
                    label="Código de referido"
                    value={values["referralCode"]}
                    touched={touched["referralCode"]}
                    onBlur={handleBlur("referralCode")}
                    helperErrorText={errors["referralCode"]}
                    onChange={handleChange("referralCode")}
                  />
                )}
              </Column>
              <Button
                loaderId="sign-up"
                disabled={disabled}
                label=" Registrarse"
                style={{width: "100%"}}
                onClick={() => handleSubmit()}
              />
            </Column>
          );
        }}
      </Formik>
    </Container>
  );
};
