import {State} from "./types";
import {userInitialState} from "@redux/initial-states";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  referralUser: userInitialState,
  request: {
    referralUser: setRequestStatus("initial"),
  },
};
