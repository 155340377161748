import {CSSProperties} from "react";
import {AVATAR_PLACEHOLDER} from "utils/avatar-placeholder";

interface AvatarProps extends CSSProperties {
  src: string;
  alt?: string;
  size?: number;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const {src, size = 50, alt = "avatar", ...cssStyle} = props;
  return (
    <img
      alt={alt}
      width={size}
      height={size}
      src={src || AVATAR_PLACEHOLDER}
      style={{objectFit: "cover", borderRadius: 100, ...cssStyle}}
    />
  );
};
