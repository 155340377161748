import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {findUserByReferralCode} from "./thunks";
import {setRequestStatus} from "@redux/request-status";
import {userInitialState} from "@redux/initial-states";

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetReferralUser: (state) => {
      state["referralUser"] = userInitialState;
      state["request"]["referralUser"] = setRequestStatus("initial");
    },
  },
  extraReducers: function (builder) {
    builder.addCase(findUserByReferralCode.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["referralUser"] = data;
      state["request"]["referralUser"] = setRequestStatus("success");
    });

    builder.addCase(findUserByReferralCode.rejected, function (state) {
      state["request"]["referralUser"] = setRequestStatus("error");
    });
  },
});

export const {reducer: users} = usersSlice;
export const {resetReferralUser} = usersSlice["actions"];
