import * as Yup from "yup";
import moment from "moment";
import {BaseSignUpFormPayload} from "interfaces";

export const initialValues: BaseSignUpFormPayload = {
  name: "",
  phone: "",
  password: "",
  lastname: "",
  birthday: moment().subtract(18, "years").format("YYYY-MM-DD"),
};

export const signUpSchema = Yup.object().shape({
  name: Yup.string().required("Tu nombre es requerido"),
  lastname: Yup.string().required("Tu apellido es requerido"),
  password: Yup.string().required("La contraseña es requerida"),
  birthday: Yup.string().required("Tu fecha de nacimiento es requerida"),
});
