import {useState} from "react";
import {Column, Typography} from "components";
import {typographyErrorProps} from "../Input/styled";
import {
  parseCountry,
  defaultCountries,
  PhoneInput as Input,
} from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneInputProps {
  value: string;
  label?: string;
  touched?: boolean;
  helperText?: string;
  helperErrorText?: string;
  onChangePhoneNumber: (value: string) => void;
}

function buildMask(code: string = "+58"): string {
  const obj: Record<string, string> = {
    "+58": "... ... ....",
    "+1": "... ... ....",
    "+57": "... ... ....",
    "+54": "... ... ....",
    "+51": "... ... ...",
    "+593": ".. ... ....",
  };
  return obj[code];
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  touched,
  helperText,
  helperErrorText,
  onChangePhoneNumber,

  label = "Teléfono móvil",
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string>("+58");
  const mask = buildMask(selectedCountry);
  const countries = defaultCountries.filter((country) => {
    const {iso2} = parseCountry(country);
    return ["ve", "co", "us", "ar", "ec", "pe"].includes(iso2);
  });

  return (
    <Column gap={5} width="100%">
      {label && <Typography fontSize={13}>{label}</Typography>}
      <Input
        value={value}
        defaultCountry="ve"
        countries={countries}
        style={{width: "100%"}}
        defaultMask={mask}
        inputStyle={{
          width: "100%",
          height: "57px",
          fontSize: "1rem",
          fontFamily: "inherit",
          borderRadius: "0 15px 15px 0",
        }}
        onChange={(phone, {country}) => {
          onChangePhoneNumber(phone);
          setSelectedCountry(`+${country["dialCode"]}`);
        }}
        dialCodePreviewStyleProps={{style: {height: "57px"}}}
        countrySelectorStyleProps={{
          buttonStyle: {
            height: "57px",
            borderRadius: "15px 0 0 15px",
            width: "80px",
          },
        }}
      />
      {helperText && (
        <Typography style={{textAlign: "right"}} fontSize={12}>
          {helperText}
        </Typography>
      )}
      {helperErrorText && touched && (
        <Typography {...typographyErrorProps}>{helperErrorText}</Typography>
      )}
    </Column>
  );
};
